@import url('https://fonts.googleapis.com/css?family=Montserrat');

.App {
  user-select: none;
  font-family: 'Montserrat';
}

strong {
  font-family: 'Montserrat';
}

.about {
  z-index: 3;
  display: inline-flex;
  position: absolute;
  margin: 22vh 5vw;
  width: 90vw;
  max-width: 1300px;
  height: 70vh;
  box-shadow: 15px 15px 0px 0px #F89272;
  -webkit-box-shadow: 15px 15px 0px 0px #F89272; 
  background-color: white;
  text-align: left;
  /* flex:var(--condiv-flex); */
  
}

.portfolio {
  z-index: 3;
  display: inline-block;
  position: absolute;
  margin: 22vh 5vw;
  width: 90vw;
  min-width: 700px;
  box-shadow: 15px 15px 0px 0px #F89272;
  -webkit-box-shadow: 15px 15px 0px 0px #F89272; 
  height: 70vh;
  background-color: white;
  text-align: left;
  /* flex:var(--condiv-flex); */
}

.no-scroll {
  overflow-y: hidden;
}

.dropdown-menu {
  z-index:10;
  background: black;
  width: 60%
}

.portContent {
  width: 100%;
  height: calc( 100% - 72px );;
  overflow-y: auto;
  overflow-x: hidden;
}

.no-scroll {
  overflow-y: hidden;
}

#coming-soon {
  margin-left: 30px;
  font-family: 'Montserrat';
 font-size: 30pt;
}

.slider {
  padding-top: 5px;
  padding-left: 5px;
}

.slideImg{
  width: 23%;
  margin-top: 0px;
  padding-top: 5px;
  padding-bottom: 5vh;
  height: 125%;
}

.slideImg img{
  padding-left: 40px;
  width: 46vh;
}


.player {
  position: relative;
  margin-top: 0;
  padding-left: 50px;
}

.music {
  font-family: 'Montserrat';
  z-index: 3;
  display: inline-block;
  position: absolute;
  margin: 22vh 5vw;
  width: 60vw;
  max-width: 1300px;
  min-width: 1000px;
  box-shadow: 15px 15px 0px 0px #F89272;
  -webkit-box-shadow: 15px 15px 0px 0px #F89272; 
  height: 70vh;
  background-color: white;
  text-align: left;
  /* flex:var(--condiv-flex); */
}

.portfolio .player-cover {
  display: none;
}

.player .player-control {
  max-width:70vw;
}

.musicPort {
  display: flexbox;
  width: 100%;
  justify-content: space-between;
  flex-direction: row;
}

.contact {
  z-index: 3;
  display: inline-block;
  position: absolute;
  margin: 22vh 5vw;
  padding-bottom: 40px;
  padding-right: 25px;
  width: auto;
  height: auto;
  min-width: 350px;
  background-color: white;
  text-align: left;
  box-shadow: 15px 15px 0px 0px #F89272;
  -webkit-box-shadow: 15px 15px 0px 0px #F89272; 
  /* flex:var(--condiv-flex); */
}


.contact h2 {
  margin-left: 7vw;
  margin-bottom: 0 ;
}

.contact h1 {
  margin-bottom: 0 ;
}

.subtopic
{
  font-family: 'Montserrat';
  margin-left:30px;
}

.subtopic3
{
  font-family: 'Montserrat';
  font-size: 20px;
  margin-left:20px;
  font-weight: 100;
}

.subtopic4
{
  font-family: 'Montserrat';
  font-size: 16pt;
  margin-left:50px;
  margin-top: 0;
  font-weight: 100;
}

.subtopic2
{
  font-weight: bolder;
  font-family: 'Montserrat';
  margin-left: 20px;
}

.bg{
  display: inline-flex;
  background: #fadcd3;
  padding-bottom: 10px;
}

.portSubDivider {
  width: 2.5px;
  height: 50px;
  background: #F89272;
}

.aboutGif img{
  position: absolute;
  z-index:3;
  height: 60vh;
  margin-top: 58vh;
  margin-left: 55vw;
  transform: translateY(-50%);
}


.aboutText {
  overflow-y: auto;
  width: 50%;
  height: 70vh;
}

.aboutGif2 img{
  display: none;
}


.LiveBackDrop {
  position: absolute;
  background-color: black;
  z-index: 0;
  /* filter: blur(7px); */
  transition: filter 0.5s;
  /* transform: scale(1.1);  */
}

.contactSketch {
  z-index:1;
  position: absolute;
  
}

.UI {
  z-index: 2;  
}

.splashCard {
  display: table-cell;
  height: 60vh;
  width: 46.4vw;
  vertical-align: middle;
  text-align: center;
}

.splashCard img{
  transition: opacity 0.5s;
  position: absolute;
  width: 50vw;
} 

.splashCardCorner img{
  z-index: 4;
  vertical-align: middle;
  position: absolute;
  top: 50%; left: 4vw;
  transform: translate(0,-50%);
  width: 280px;
} 

.enterPar {
  background-color: #F89272;
  width: 80vw;
  display: flex;
  position: absolute;
  margin-top: auto;
  justify-content: flex-end;
}

.enter {
  text-align: center;
  position: absolute;
  font-family: 'Montserrat';
  font-weight: 400;
    color: black;
  font-size: 20pt;
  border: none;
  background: white;
  outline: none;
  width: 180px;
  height: 60px;
  cursor: pointer;
  margin-top: 85vh;
  z-index: 4;
  /* margin-left: 80vw; */
}

.enterTag {
  position: absolute;
  display: flex;
  z-index: 4;
  width: 10vw;
  height: 100vh;
  margin-left: 50vw;
  background-color: #F89272;
}

.enter:hover {
  outline: solid;
  color: white;
  background-color: black;
  outline-width: 3px;
  /* box-shadow:  5px 10px 10px black; */
}

.portMenuDivSep {
  height: 3px;
  width: 100%;
  background: #F89272;
}

#projItemsDiv {
  display: flex;
  width: 100%;
  overflow: auto;
  flex-direction: row;
  justify-content: space-around;
}


.portProjItem {
  background: none;
  
  display: flex;
  margin: 0px;
  width: 20vw;
  height: 20vw;
  overflow: hidden;
  justify-content: center;
  padding-bottom: 20px;
}

.portProjItem img {
  height:100%;
}

.portProjItem :hover{
  filter: blur(1px);
  cursor: pointer;
}

.projTitle {
  z-index:10;
  font-size: 14pt;
  font-family: Montserrat;
  text-align: center;
  /* position: absolute; */
  background: black;
  color: white;
  min-width: 150px;
  width: 70%;
  /* margin-top: 120px; */
  padding:10px;
}

.portProjItem p:hover {
  filter: none;
  cursor: unset;
}

.active a
{
  font-weight: 800;
  text-decoration: overline;
  text-decoration-color: coral;
  z-index: 4;
}

.conLink {
  font-weight: 400;
  font-family: 'Montserrat';
  text-decoration: overline;
  text-decoration-color: coral;
  color: rgb(0, 141, 206);
  text-decoration: none;
  z-index: 9;
}

.conLink:visited {
  color: rgb(0, 141, 206);
}

.openPalm {
  cursor: grab;
}

.closedPalm {
  cursor: grabbing;
}


nav
{
  
  position: absolute;
  font-family: 'Montserrat';
  display: flex;
  z-index: 4;
  width: 100vw;
  height: 15vh;
  min-height: 100px;
  justify-content: flex-end;
  vertical-align: middle;
  background:black;
  text-align: center;
  text-decoration: none;
  
  /* background: linear-gradient(180deg, rgba(0,0,0,1) 20%, rgba(0,0,0,0) 100%); */
}

h1 {
  font-family: 'Montserrat';
}

nav ul
{
  position: absolute;
  height: auto;
  display: flex;
  list-style-type: none;
  width: auto;
  font-family: 'Montserrat';
  color: white;
  padding-right: 5vw;
  padding-top: 0.5vh;
  z-index: 1;

  /* background-color: teal; */
  justify-content: flex-end;
  vertical-align: middle;
  text-align: left;
  text-decoration: none;
  font-size: 23pt;

}

nav ul li 
{
  font-family: 'Montserrat';
  font-weight: medium;
  color: white;
  text-decoration-color: #F89272;
  z-index: 4;
  list-style: none;
  margin-left: 30px;
  text-decoration: none;
}


nav ul li:visited {
  font-family: 'Montserrat';

  text-decoration: none;
}



nav ul li a
{
  color: white;
  z-index: 4;
  font-family: 'Montserrat';
  text-decoration: none;
  
}

nav ul li a :visited
{
  color: green;
  text-decoration: none;
  text-decoration-color: #F89272;
  text-decoration-line: none;
  margin:40px;
  z-index: 4;
}

.portBtn{
  font-family: 'Montserrat';
  font-weight: medium;
  font-size: 19pt;
  text-align: center;
  outline: none;
  margin: 10px;
  cursor: pointer;
  margin-bottom: 0px;
  background: none;
  border: none;
  padding:10px;
  width: 15vw;
  height: auto;
}

.portBtn:hover{
  color: white;
  background: #f56134;
}

.portBtn:active{
  color: white;
  background: #dd491d;
}


.portBtnActive{
  color: white;
  background: #F89272;
  /* box-shadow: 2px 2px 0px 0px #8b2505; */
}


.enter-off {
  display: none;
}

.mobMenu {
  display: none;
}

.displayPageMob {
  font-family: 'Montserrat';
  font-weight: medium;
  font-size: 16pt;
  text-align: left;
  z-index: 3;
  /* margin-left: 70vw; */
  /* font-size: 3vh;  */
  /* width: 25vw; */
  color: white;
  margin-top: 0%;
  margin-right: 17vw;
  position: relative;
}



.ddDiv {
  width: 50vw;
  height: 7vh;
  background-color: black;
  margin-top: 2px;
  text-align: right;
  padding-top: 2vh;
}

.ddDiv:hover {
  background-color: white;
}

.ddDiv:hover li{
  color: black;
}

.ddDiv li{
  position: relative;
  padding-right: 6vw;
}



.dropdownMobile { 
  position: absolute;
  list-style: none;
  text-align: center;
  background-color: rgb(255, 214, 202);;
  z-index: 6;
  top: 12vh;
  left: 50vw;
  height: auto;
  background-color: rgb(255, 255, 255);

  
}



.dropdownMobile a {
  color: white;
  text-decoration: none;
  text-decoration-color: #F89272;
  z-index: 4;
  /* float:right; */
  font-family: 'Montserrat';
  font-weight: medium;
  color: white;
  z-index: 4;
}


.mobMenuIcon img{
  padding: 7vw;
}

.mobMenuIcon img{
  z-index: 4;
  vertical-align: middle;
  position: absolute;
  top: 50%; left: 90%;
  transform: translate(-50%,-50%);
  width: 8vw;
} 

.mobMenuIcon p{
  z-index: 3;
  vertical-align: middle;
  position: absolute;
  top: 50%; 
  padding-right: 55vw;
  transform: translate(-50%,-50%);
} 

.mobMenuIcon :hover {
  cursor: pointer;
}

.brShow {
  display: unset;
}

#mobile-port-dropdown{
  display: none;
  background: #F89272;
  position: absolute;
  color: white;
  margin: 10px;
  height: 5vh;
  font-size: 20pt;
  width: auto;
  border-style: none;
  vertical-align: middle;
  font-family: 'Montserrat';

}

#carrot img{
  width: 10vw;
}

#dd-title {
  display: flexbox;
  justify-content: space-between;
}

#dd-title img{
  margin-left: 20px;
  width: 28px;
  height: 14px;
}

.mob-port-subject{
  font-family: 'Montserrat';
  color: white;
  margin-top: 8px;
  margin-bottom: 8px;
  text-align: center;
  margin-left: 10px;
  height: 5vh;
  width: 130%;
  font-size: 20pt;
  text-decoration: none;
  display: inline-block;
  background-color: #e73a05;
  vertical-align: middle;

}


#mobile-port-dropdown:hover{
  color: white;
  background: #f56134;
}

#mobile-port-dropdown:active{
  color: white;
  background: #dd491d;
}


#mobile-port-dropdown-active{
  color: white;
  background: #F89272;
  display: none;
  /* box-shadow: 2px 2px 0px 0px #8b2505; */
}

.loadC {
  width: 100%;
  height: 100%;
  color:black;
  position: absolute;
}

.stripes {
  position: absolute;
  scale: 1.5;
  width: 1vw;
  height: 1vh;
}


#interact {
    overflow: hidden;
  cursor: cell;
  justify-content: center;
  width: 95%;
  margin-left: 2.5%;
}

.cell-autom {
  margin-bottom: 20px;
  padding-bottom: 0px;
  /* border-style: solid;
  border-width: 4px; */
  margin-top: 0px;
  width: fit-content;
  height: fit-content;
  background: black ;
  cursor: cell;
  /* filter: blur(2px); */
  -webkit-user-select: none;
  -khtml-user-select: none;
  -webkit-touch-callout : none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  box-shadow: 0 1px 1px rgba(0,0,0,0.12), 
              0 2px 2px rgba(0,0,0,0.12), 
              0 4px 4px rgba(0,0,0,0.12), 
              0 8px 8px rgba(0,0,0,0.12),
              0 16px 16px rgba(0,0,0,0.12);
}

/* .cell-autom:hover{
  filter: blur(0px);

} */

.swiper img{
  height: auto;
}

/* width */
::-webkit-scrollbar {
  width: 20px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(255, 205, 189); 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(255, 99, 51); 
}

.fade-in {
  animation: fadeIn ease 1s;
  -webkit-animation: fadeIn ease 1s;
  -moz-animation: fadeIn ease 1s;
  -o-animation: fadeIn ease 1s;
  -ms-animation: fadeIn ease 1s;
  }
  @keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
  }
  
  @-moz-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
  }
  
  @-webkit-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
  }
  
  @-o-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
  }
  
  @-ms-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
  }



  @media only screen and (max-width: 700px) {

    .splashCard img{
      width: 100vw;
      position: relative;
    }

    .slideImg{
      width: 23%;
      margin-top: 10px;
      padding-top: 0vh;
      height: 125%;
    }

    .slider {
      padding-top: 0px;
      padding-left: 0px;
    }

    .slideImg img{
      padding-top: 20px;
      padding-left: 15px;
      transform: translateX(-38%);
      width: 40vh;
    }


    .splashCardCorner img{
      z-index: 4;
      vertical-align: middle;
      position: absolute;
      top: 50%; left: 4vw;
      transform: translate(0,-50%);
      width: 24vh;
    } 

    
     nav {
          position: absolute;
          display: flex;
          z-index: 4;
          width: 100vw;
          height: 10vh;
          text-align: center;
          text-decoration: none;
          font-size: 4vh;
          /* background: linear-gradient(90deg, rgba(0,0,70,1) 60%, rgba(0,0,0,0) 100%); */
        }

      nav ul {
        display: none;
      }

      .mobMenu{
        display: flex;
        
      }

      .about{
        margin: 15vh 5vw;
        height: 60vh;
        position: absolute;
        overflow-x: auto;
      }

      .contact{
        margin: 15vh 5vw;
        position: absolute;
        min-width: unset;
      }

      .portfolio{
        margin: 15vh 5vw;
        height: 68vh;
        min-width: unset;
        width: 90vw;
        position: absolute;
      }


      .aboutText{
        height: auto;
        width: 90%;
        position: relative;
        overflow-y: auto;

      }




      .brShow {
        display: inline-flex;
      }

      .aboutGif img{
        display: none;
      }


      .enterPar {
        justify-content: center;
        width: 100vw;
      }

      .enter {
        margin:0 -7%;
        margin-top: 60vh;
        display:block;
        width: 40vw;
        position: absolute;
        justify-content: center;
      }

      /* .aboutGif img{
        display: none;
      } */

      .bg{
        display: none;
        background: #fadcd3;
      }

      #mobile-port-dropdown {
        display: inline-block;
        position: relative;
      }

      .portSubDivider {
        display: none;
      }

      .slider {
        padding-left: 5px;
      }
      
      .slideImg{
        width: 23%;
        height: 100%;
      }

      .subtopic {
        margin-left: 15px;
      }

      #projItemsDiv {
        flex-direction: column;
      }

      .portProjItem {
        
        margin-bottom: 8vw;
        margin-left: 30px;
        width: 60vw;
        height: 60vw;
      }

  }
 
  @media only screen and (max-width: 1080px) {
    .enterPar {
      width: 70vw;
    }
  }